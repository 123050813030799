import { action, autorun, makeAutoObservable, runInAction } from 'mobx'
import { BangBangTangRequest } from '../utils/request'

import { BehaviorSubject, combineLatest, of } from 'rxjs'
import { catchError, filter, take, tap } from 'rxjs/operators'
import { store } from './store.root'
import { toStream } from 'mobx-utils'
import { addMinutes, format } from 'date-fns'
import { FetchRequest } from '../utils/fetch'
import { ActivityIndicator, Loading, Modal, Toast } from 'zarm'

interface IFrontendAuth {
  end_time: number
  end_time_text: string
  id: number
  module_name: string
  module_status: string
  module_status_text: string
  module_title: string
  start_time: number
  start_time_text: string
}

export class UserStore {
  public readonly _loginComplete = new BehaviorSubject(false) // 是否已经登录完毕
  private readonly _loginSuccess = new BehaviorSubject(false) // 是否已经登录完毕
  initialLoginCompleted = this._loginComplete.pipe(
    filter((value) => value),
    take(1)
  )
  login_pending: boolean | null = null // 登陆进程，null代表未开始登陆，true代表正在登陆，false代表登陆结束

  token: string = ''
  app_id: string = ''
  username: string = ''
  avatar: string = ''
  mobile: string = ''
  create_time: string = ''
  staff_name: string = ''
  module: Array<IFrontendAuth> = []
  rulelis: Array<string> = []
  staffauth: Array<string> = []
  is_saleman: string = ''
  need_info: boolean = false
  id: number = 0

  isReqSize: number = 0

  redirectToWechatLogin(scope: string, doNotNeedToLogIn: boolean = false) {
    // 毕业季排除
    if (
      location.pathname.indexOf('/build/graduation-sign') > -1 &&
      !doNotNeedToLogIn
    ) {
      return
    }
    if (
      location.pathname.indexOf('/build/film-download-new') > -1 &&
      !doNotNeedToLogIn
    ) {
      return
    }
    if (
      location.pathname.indexOf('/build/theAgreementSigned/code') > -1 &&
      !doNotNeedToLogIn
    ) {
      return
    }
    const current_url = window.location.href
    if (!localStorage.getItem('redirect_url')) {
      localStorage.setItem('redirect_url', location.pathname + location.search)
    }
    this.getAppId().then(() => {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
        this.app_id
      }&redirect_uri=${encodeURIComponent(
        current_url
      )}&response_type=code&scope=${scope}&state=1&forcePopup=true&forceSnapShot=true#wechat_redirect`
    })
  }

  getAppId(): Promise<null> {
    return new Promise<null>((resolve) => {
      BangBangTangRequest({
        url: '/wap/wap2/getWechatConfig',
        data: {},
        method: 'GET',
      }).subscribe((value) => {
        this.app_id = value.app_id
        resolve(null)
      })
    })
  }
  completeLogin(res: any) {
    const urlParams = new URLSearchParams(window.location.search)
    let newUrlSearchString = ''
    Array.from(urlParams.keys()).forEach((key) => {
      const value = urlParams.get(key)
      if (key !== 'code' && key !== 'state') {
        newUrlSearchString =
          newUrlSearchString === ''
            ? `?${key}=${value}`
            : newUrlSearchString + `&${key}=${value}`
      }
    })
    const mobile = res.mobile
    const avatar = res.avatar
    const openname = res.openname
    if (res.token) {
      const token = res.token
      this.changeToken(token)
    }
    // this.changeUserInfo(avatar, openname);
    this.changePhone(mobile)
    runInAction(
      () => (this.create_time = format(res.createtime * 1000, 'yyyy-MM-dd'))
    )
    this.loginCompleted()
    store.websiteRuleStore.updateFrontendAuth()
    // 判断是否存在手机
    console.log('判断是否存在手机')
    if (MobileVerificationAuth(mobile)) {
      // 无手机号处理
      console.log('开始无手机号处理')
      // 判断路由是否包含 register
      console.log(
        '判断路由是否包含 register',
        document.location.href.includes('register')
      )
      if (document.location.href.includes('register')) {
        store.routerStore.replace(document.location.pathname)
      } else {
        location.href =
          '/build/register?next=' +
          encodeURIComponent(window.location.pathname + newUrlSearchString)
      }
    } else {
      // 存在手机号
      console.log('存在手机号')
      console.log(window.location)
      // 如果当前路径为 /build/register 则跳转
      if (window.location.pathname === '/build/register') {
        localStorage.removeItem('logon_size_s')
        store.routerStore.replace('/build/my-info')
        return
      }
      store.routerStore.replace(window.location.pathname + newUrlSearchString)
    }
  }

  getUserInfo() {
    // 模特签约扫码
    if (location.pathname.indexOf('/build/theAgreementSigned/code/') > -1)
      return
    // 在注册页不执行
    if (location.pathname === '/build/register') return
    // 在报名页不执行
    // if (
    //   location.pathname.indexOf('/build/activity-registration') > -1 &&
    //   !((localStorage.getItem('mobile') || '').length === 11)
    // )
    //   return
    localStorage.setItem('redirect_url', location.pathname + location.search)
    localStorage.setItem('login_url_redri', location.pathname + location.search)
    // 无手机无token不执行 测试 毕业季
    // if (localStorage.getItem('token') && !localStorage.getItem('mobile')) {
    //   return
    // }
    return BangBangTangRequest({
      url: '/wap/wap2/getUserInfo',
      method: 'POST',
      data: { token: store.userStore.token },
    }).subscribe((value) => {
      runInAction(
        () => (this.create_time = format(value.createtime * 1000, 'yyyy-MM-dd'))
      )
      this.changeIsSaleman(value.is_saleman)
      this.changePhone(value.mobile)
      this.changeUserInfo(
        value.avatar,
        value.openname,
        value.staff_name,
        value.need_info,
        value.id
      )
      // store.websiteRuleStore.updateFrontendAuth()
      this.loginCompleted()
      this.completeLogin(value)

      // location.pathname.indexOf('/build/activity-registration') === -1 &&
      if (location.pathname === '/build/' && value.need_info) {
        const store_need_info_expiretime = localStorage.getItem(
          'need_info_expiretime'
        )
        if (store_need_info_expiretime) {
          /// 判断是否过期
          if (new Date().getTime() > parseInt(store_need_info_expiretime)) {
            this.loginPrompt(value.need_info, value.need_info_expiretime)
          }
        } else {
          this.loginPrompt(value.need_info, value.need_info_expiretime)
        }
      }
    })
  }

  private loginCompleted() {
    this._loginComplete.next(true)
  }

  loginPrompt(need_info: boolean, need_info_expiretime: number) {
    if (need_info) {
      const expirationTime = addMinutes(new Date(), need_info_expiretime / 60)
      console.log('过期时间:', expirationTime)
      localStorage.setItem(
        'need_info_expiretime',
        new Date(expirationTime).getTime().toString()
      )
      const modal = Modal.confirm({
        title: '提示',
        content:
          '为了方便客服与您联系，提供更优质的服务，需要获取您的微信头像和昵称',
        onCancel: () => {
          console.log('点击cancel')
        },
        onOk: () => {
          console.log('点击ok')
          localStorage.removeItem('token')
          localStorage.setItem('scope', 'snsapi_userinfo')
          store.userStore.loginFromWechat()
        },
      })
    }
  }

  async loginFromWechat(doNotNeedToLogIn: boolean = false) {
    ///// 排除一些页面无需登录
    // 笑脸墙
    // location.pathname.indexOf('/build/activity-registration') > -1 &&
    // if (!doNotNeedToLogIn) {
    //   return
    // }
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const scope = localStorage.getItem('scope')
    if (!code) {
      store.userStore.redirectToWechatLogin(
        scope || 'snsapi_base',
        doNotNeedToLogIn
      )
      return
    }
    let wap_string = ''
    if (
      location.pathname.indexOf('/build/activity-registration') > -1 ||
      location.pathname.indexOf('/build/selection-activities') > -1 ||
      location.pathname.indexOf('/build/film-download-new') > -1 ||
      location.pathname.indexOf('/build/theAgreementSigned/code') > -1
    ) {
      wap_string = 'yes'
    } else {
      wap_string = 'no'
    }
    await BangBangTangRequest({
      url: '/wap/wap2/wechatLogin',
      method: 'POST',
      data: {
        code,
        info: scope === 'snsapi_userinfo' ? 'yes' : 'no',
        wap: wap_string,
      },
    }).subscribe((result) => {
      const mobile = result.mobile
      const avatar = result.avatar
      const staff_name = result.staff_name
      const openname = result.openname
      const token = result.token
      const subscribeRes = result.subscribe // 0 未关注 1 已关注
      const shareUrl = result.shareUrl
      const follow_qr = result.follow_qr
      const need_info = result.need_info
      console.log('开始写入token')
      localStorage.setItem('token', token)
      localStorage.setItem('subscribe', subscribeRes)
      localStorage.setItem('shareUrl', shareUrl)
      localStorage.setItem('follow_qr', follow_qr)
      localStorage.setItem('wap_openid', result.wap_openid)

      const is_saleman = result.is_saleman || ''

      this.changeUserInfo(avatar, openname, staff_name, need_info, result.id)
      this.changePhone(mobile)
      this.changeToken(token)
      this.changeIsSaleman(is_saleman)
      runInAction(
        () =>
          (this.create_time = format(result.createtime * 1000, 'yyyy-MM-dd'))
      )
      this.loginCompleted()
      // store.websiteRuleStore.updateFrontendAuth()
      // 开始判断是否存在 token 和手机号
      if (token && MobileVerificationAuth(mobile)) {
        //  MobileVerificationAuth(mobile)
        // 判断当前页面是否存在注册
        console.log(
          '判断当前页面是否存在register',
          document.location.href.includes('register')
        )
        if (document.location.href.includes('register')) {
          store.routerStore.replace(document.location.pathname)
        } else {
          store.routerStore.replace(
            '/build/register?next=' +
              encodeURIComponent(
                window.location.pathname + window.location.search
              )
          )
        }
      } else {
        console.log('token && !mobile 不成功跳转执行')
        // store.routerStore.replace(window.location.pathname);
        store.routerStore.replace(
          window.location.pathname + window.location.search
        )
      }
      this.completeLogin(result)
      store.userStore.getUserInfo()
      const scope = localStorage.getItem('scope')
      if (scope && scope === 'snsapi_userinfo') {
        localStorage.removeItem('scope')
      }
    })
  }

  /**
   * 获取页面的权限信息
   */
  getPageAuthInfo() {
    let obj: { token?: string } = {}
    if (store.userStore.token) {
      obj.token = store.userStore.token
    }
    return BangBangTangRequest({
      url: '/wap/wap2/getFrontendAuth',
      method: 'POST',
      data: obj,
    }).subscribe((result) => {
      this.changeFrontendAuth(result.module, result.rulelist, result.staffauth)
    })
  }

  @action
  changeFrontendAuth(
    module: Array<IFrontendAuth>,
    rulelis: Array<string>,
    staffauth: Array<string>
  ) {
    this.module = module || []
    this.rulelis = rulelis || []
    this.staffauth = staffauth || []
  }

  changePhone(mobile: string) {
    this.mobile = mobile
    if (String(mobile).length === 11) {
      localStorage.setItem('mobile', String(mobile))
    }
  }

  changeUserInfo(
    avatar: string,
    openname: string,
    staff_name: string,
    need_info: boolean,
    id: number
  ) {
    this.avatar = avatar
    this.username = openname
    this.staff_name = staff_name
    this.need_info = need_info
    this.id = id
  }

  changeIsSaleman(is_saleman: string) {
    this.is_saleman = is_saleman
  }

  constructor() {
    makeAutoObservable(this)
    combineLatest([this.initialLoginCompleted, toStream(() => this.mobile)])
      .pipe(filter((value) => value[0] && !!value[1]))
      .subscribe((value) => (value ? this._loginSuccess.next(!!value) : null))
    autorun(() => {
      if (this.is_saleman) {
        window.localStorage.setItem('is_saleman', this.is_saleman)
      }
      if (this.token) {
        window.localStorage.setItem('token', this.token)
      }
    })
  }

  changeToken(value: string) {
    this.token = value
  }
}

// 有
// false 正常
// true 跳转注册页
function MobileVerificationAuth(mobie: string) {
  if (!mobie) {
    if (
      location.pathname.indexOf('/build/activity-registration') > -1 ||
      location.pathname.indexOf('/build/selection-activities') > -1
    ) {
      // 排除验证手机
      return false
    }
    if (location.pathname.indexOf('/build/graduation-sign') > -1) {
      return false
    }
    return true
  }
  return false
}

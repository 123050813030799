import OSS from 'ali-oss'
import COS from 'cos-js-sdk-v5'
//// 腾讯云
import { store } from '../store/store.root'
import { FetchRequest } from './fetch'
import { Loading, Toast, ActivityIndicator } from 'zarm'

///// 工具
export function timestampToTime() {
  var date = new Date() //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear()
  var M =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var D = date.getDate()
  return `${Y}${M}${D}`
}

export function random_20() {
  var data = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
  ]
  var result = ''
  for (var i = 0; i < 20; i++) {
    const r = Math.floor(Math.random() * 16)
    result += data[r]
  }
  return result
}

// 腾讯云
export async function cosUpload(fileName: string, ImagesBlob: any) {
  const configOOS = await FetchRequest('/wap/sanction/getOssConfig', {
    token: localStorage.getItem('token') || store.userStore.token,
  })

  const cos = new COS({
    getAuthorization: async function (options, callback) {
      // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
      // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
      // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
      const data = await FetchRequest('/addons/cos/index/getSTS', {
        // token: localStorage.getItem('token') || store.userStore.token,
      })
      callback({
        TmpSecretId: data.data.credentials.tmpSecretId,
        TmpSecretKey: data.data.credentials.tmpSecretKey,
        SecurityToken: data.data.credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000900
      })
    },
  })

  const today = timestampToTime()
  const uploadFileName = random_20()
  const suffix = fileName.substring(fileName.lastIndexOf('.') + 1)

  const objectName = `/uploads/${
    localStorage.getItem('admin_store_id') || '1'
  }/img/photopage/${today}/${uploadFileName}.${suffix}`

  cos.putObject(
    {
      Bucket: configOOS.data.bucket,
      Region: configOOS.data.region,
      Key: objectName,
      StorageClass: 'STANDARD',
      Body: ImagesBlob, // 上传文件对象
      onProgress: function (progressData) {
        console.log(JSON.stringify(progressData))
      },
    },
    function (err, data) {
      if (err) {
        // alert("图片上传失败")
      }
    }
  )
  return objectName
}

export async function cosUploadNotToken(fileName: string, ImagesBlob: any) {
  const configOOS = await FetchRequest('/api/index/getOssConfig', {})

  const cos = new COS({
    getAuthorization: async function (options, callback) {
      // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
      // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
      // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
      const data = await FetchRequest('/addons/cos/index/getSTS', {
        // token: localStorage.getItem('token') || store.userStore.token,
      })
      callback({
        TmpSecretId: data.data.credentials.tmpSecretId,
        TmpSecretKey: data.data.credentials.tmpSecretKey,
        SecurityToken: data.data.credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000900
      })
    },
  })

  const today = timestampToTime()
  const uploadFileName = random_20()
  const suffix = fileName.substring(fileName.lastIndexOf('.') + 1)

  const objectName = `/uploads/${
    localStorage.getItem('admin_store_id') || '1'
  }/img/photopage/${today}/${uploadFileName}.${suffix}`

  cos.putObject(
    {
      Bucket: configOOS.data.bucket,
      Region: configOOS.data.region,
      Key: objectName,
      StorageClass: 'STANDARD',
      Body: ImagesBlob, // 上传文件对象
      onProgress: function (progressData) {
        console.log(JSON.stringify(progressData))
      },
    },
    function (err, data) {
      if (err) {
        // alert("图片上传失败")
      }
    }
  )
  return objectName
}

// base6 => blob
export function dataURLtoBlob(dataurl: any) {
  var arr = dataurl.split(',')
  //注意base64的最后面中括号和引号是不转译的
  var _arr = arr[1].substring(0, arr[1].length - 2)
  var mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(_arr),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], {
    type: mime,
  })
}

// 上传到cos
export async function cosUploadFile(
  File: File,
  objectName: string,
  px: number = 800
): Promise<string> {
  return new Promise(async (res, rej) => {
    Loading.show({
      content: '头像上传中',
    })

    const configOOS = await FetchRequest('/wap/graduation/getCosConfig', {
      token: localStorage.getItem('token') || store.userStore.token,
    })

    const cos = new COS({
      getAuthorization: async function (options, callback) {
        // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
        // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
        // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
        const data = await FetchRequest('/addons/cos/index/getSTS', {
          token: localStorage.getItem('token') || store.userStore.token,
        })
        callback({
          TmpSecretId: data.data.credentials.tmpSecretId,
          TmpSecretKey: data.data.credentials.tmpSecretKey,
          SecurityToken: data.data.credentials.sessionToken,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000900
        })
      },
    })

    const uploadFileName = random_20()

    cos.putObject(
      {
        Bucket: configOOS.data.bucket,
        Region: configOOS.data.region,
        Key: objectName,
        Body: File,
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
        },
      },
      async function (err, data_push) {
        if (err) {
          rej('')
          Loading.hide()
          return
        }
        if (data_push.statusCode === 200) {
          //// 存入
          cos.request(
            {
              Bucket: configOOS.data.bucket,
              Region: configOOS.data.region,
              Key: objectName,
              Method: 'POST',
              Action: 'image_process',
              Headers: {
                'Pic-Operations':
                  '{"is_pic_info": 1, "rules": [{"fileid": "' +
                  uploadFileName +
                  '.png", "rule": "imageMogr2/thumbnail/' +
                  px +
                  'x"}]}',
                'Cache-Controll': 'no-cache',
              },
            },
            function (err, data) {
              if (err) {
                Toast.show('图片处理失败')
                rej('file: 失败')
                Loading.hide()
                return
              }
              if (data.statusCode === 200) {
                res('https://' + data_push.Location)
              }
              Loading.hide()
            }
          )
        }
        if (err) {
          rej('file: 失败')
          Loading.hide()
        }
      }
    )
    return objectName
  })
}

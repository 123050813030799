import React, { useEffect, useState } from 'react'
import style from './index.module.scss'
import html2canvas from 'html2canvas'
import { Button, Loading, Modal, Toast } from 'zarm'
import SignatureCanvas from 'react-signature-canvas'
import XPCImages from '../../assets/xpc.png'
import { store } from '../../store/store.root'
import { FetchRequest } from '../../utils/fetch'
import { withRouter } from 'react-router-dom'
import { cosUpload, cosUploadNotToken } from '../../utils/upload'
import { observer } from 'mobx-react'
import { format } from 'date-fns'

export default observer(
  withRouter(
    (props: {
      match: {
        params: {
          id: string
        }
      }
    }) => {
      const [isSign, SetIsSign] = useState(false)

      const [data, setData] = useState<any>({})

      const [documentString, SetDocument] = useState('')

      const [signBase, setSignBase] = useState('')

      const [systemWideHigh, setSystemWideHigh] = useState({
        width: 390,
        height: 844,
      })

      useEffect(() => {
        setSystemWideHigh({
          width: window.outerWidth * 1,
          height: window.outerHeight,
        })
        GetAuthorityInfo()
        GetAgreement()
      }, [])

      async function GetAuthorityInfo() {
        const models_id = props.match.params.id
        if (!models_id) {
          store.routerStore.replace('/build')
          return
        }
        let res = await FetchRequest('/wap/models/getAuthority', {
          models_id,
        })
        if (res.code === 1) {
          // 判断是否授权过
          if (res.data.is_auth === 1) {
            // 同意过
            // 弹窗提示
            const modal = Modal.alert({
              title: '提示',
              content: '您已授权',
              onCancel: () => {
                modal.hide()
              },
            })
          }
          setData(res.data)
        } else {
          Toast.show(res.msg)
        }
      }

      async function GetAgreement() {
        let res = await FetchRequest('/wap/Models/getModelAgreement', {})
        if (res.code === 1) {
          SetDocument(res.data.agreement)
        } else {
          Toast.show(res.msg)
        }
      }

      // 生成快照
      const convertToImage = (container: any) => {
        // 设置放大倍数
        const scale = window.devicePixelRatio

        // 传入节点原始宽高
        const _width = container.offsetWidth
        const _height = container.offsetHeight

        return html2canvas(container, {
          scale: 1,
          windowWidth: _width,
          width: _width,
          height: _height,
          useCORS: true,
          allowTaint: false,
        }).then((canvas) => {
          // 返回图片的二进制数据
          return canvas.toDataURL('image/png')
        })
      }

      // base64转blob
      const base64ToBlob = function (base64Data: string) {
        let arr: any = base64Data.split(','),
          fileType = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          l = bstr.length,
          u8Arr = new Uint8Array(l)

        while (l--) {
          u8Arr[l] = bstr.charCodeAt(l)
        }
        return new Blob([u8Arr], {
          type: fileType,
        })
      }

      async function handleClickSign(baseImages: string) {
        // 调用函数，取到截图的二进制数据，对图片进行处理（保存本地、展示等）
        // const imgBlobData = await convertToImage(
        //   document.querySelector('#cvs-box')
        // )
        // // console.log(imgBlobData, '<-imgBlobData')
        const sign_image = await cosUploadNotToken(
          String(new Date().getTime()),
          base64ToBlob(baseImages)
        )
        let res = await FetchRequest('/wap/models/submitAuthority2', {
          is_auth: 1,
          models_id: props.match.params.id,
          // treaty: sign_image,
          sign_image: sign_image,
        })
        if (res.code === 1) {
          Toast.show(res.msg)
        } else {
          Toast.show(res.msg)
        }
        Loading.hide()
        GetAuthorityInfo()
      }

      const [sigCanvas, setSigCanvas] = useState<any>(null)

      if (Object.keys(data).length === 0) {
        return (
          <div style={{ textAlign: 'center', fontSize: 15, marginTop: 30 }}>
            加载中...
          </div>
        )
      }

      if (isSign) {
        return (
          <div>
            <SignatureCanvas
              penColor="black"
              ref={(ref) => {
                setSigCanvas(ref)
              }} //用于调用插件的API
              canvasProps={{
                width: systemWideHigh.width,
                height: systemWideHigh.height,
                style: { position: 'absolute', top: 0, left: 0 },
                className: 'sigCanvas',
              }}
            />
            <div className={style.actionButtons}>
              <div
                className={style.signatureToClear}
                style={{ marginBottom: 18 }}
                onClick={() => sigCanvas.clear()}
              >
                <img src={XPCImages} alt="" />
              </div>
              <div
                className={style.signatureToSubmit}
                onClick={() => {
                  if (sigCanvas.isEmpty()) {
                    Toast.show('请先签名')
                  } else {
                    Loading.show()
                    const baseImages = sigCanvas.toDataURL('image/png')
                    // setSignBase(baseImages)
                    SetIsSign(false)
                    setTimeout(() => {
                      handleClickSign(baseImages)
                    }, 1000)
                  }
                }}
              >
                <span>点击提交</span>
              </div>
              <div
                className={style.signatureToClear}
                onClick={() => sigCanvas.clear()}
              >
                <div
                  className={style.buttonToReturnTo}
                  onClick={() => SetIsSign(false)}
                >
                  返回
                </div>
              </div>
            </div>
          </div>
        )
      }

      if (data.is_auth === 1 && data.treaty.length > 0) {
        return <img src={data.treaty[0]} alt="" style={{ width: '100%' }} />
      }

      return (
        <div style={{ paddingBottom: 150 }}>
          <div className={style.contentBox} id="cvs-box">
            <h3 className={style.theHeadTitle}>留样协议</h3>
            <div className={style.userInformationDisplay}>
              <div className={style.theAvatars}>
                {((data.model_img as string[]) || []).length > 0 && (
                  <img src={data.model_img[0]} alt="" crossOrigin="anonymous" />
                )}
              </div>
              <div className={style.sSensitiveInformation}>
                <div>姓名: {data.name}</div>
                <div>性别: {data.gender === 'girl' ? '女' : '男'}</div>
                <div>出生日期: {data.birthday}</div>
              </div>
            </div>
            <div
              className={style.content}
              dangerouslySetInnerHTML={{ __html: documentString }}
            ></div>
            <div className={style.theUserSignature}>
              <div>经办人: {data.creater}</div>
              <div>监护人签名: </div>
              <img src={signBase} alt="" />
            </div>
            <div className={style.signingDate}>
              签约日期: {format(new Date().getTime(), 'yyyy-MM-dd')}
            </div>
          </div>
          <div className={style.signButton}>
            <Button
              theme="primary"
              shape="round"
              block
              onClick={() => {
                // handleClickSign()
                SetIsSign(true)
                const modal = Modal.alert({
                  title: '提示',
                  content: '请横屏签约',
                  onCancel: () => {
                    modal.hide()
                  },
                })
              }}
            >
              点击签署
            </Button>
          </div>
        </div>
      )
    }
  )
)
